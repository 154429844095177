import React from 'react';
import useCurrentTime from '../../hooks/useCurrentTime';

const Logo = () => {
    const currentTime = useCurrentTime();

  return (
    <div 
    className='cards__logo-wrapper'>
        <img src={currentTime?'/OmFriesWhite.png':'/OmFriesBlack.png'} alt="OM FRIES WORLD" className='cards__logo'/>
    </div>
  )
}

export default Logo