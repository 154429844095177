import React from "react";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, OrbitControls } from "@react-three/drei";
import Card from "./Card";
import RandomWeight from "../../hooks/TextRandomiser";
import useWindowSize from "../../hooks/useWindowSize";
import Stars from "../home/Stars";

import "./Cards.scss";

const CardPreview = (props) => {
  const { selectedCard, previewOpen, setPreviewOpen, selectedWeek } = props;
  const windowSize = useWindowSize();

  return (
    <section className={`cards__preview-wrapper ${previewOpen ? "active" : ""}`}>
          <div className="cards__title preview">
            <button
              className="cards__back"
              onClick={() => {
                setPreviewOpen(false);
              }}
            ><RandomWeight text={"Back"} />
            </button>
            {selectedCard ? <RandomWeight
              text={`Week ${selectedWeek}: ${selectedCard.cardTitle}`}
            />:''}
          </div>
          {selectedCard ? (
          <Canvas>
            {windowSize.width >= 750 ? (
              <PerspectiveCamera makeDefault position={[0, 0, 10]} />
            ) : (
              <PerspectiveCamera makeDefault position={[0, 0, 15]} />
            )}
            <OrbitControls enableZoom={false} />
            <Stars />
            <Card cardImage={selectedCard.cardImage} />
          </Canvas>
          ) : ('')}
    </section>
  );
};

export default CardPreview;
