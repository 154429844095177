import { useFrame } from '@react-three/fiber';
import React, {useRef, } from 'react'
import Planet from './Planet';
const Planets = (props) => {

    const {planetData} = props
    const planets = useRef();

    useFrame(({clock})=>{
        planets.current.rotation.y = clock.getElapsedTime() * 0.1;
    })
  return (
    <group ref={planets} >
    {planetData &&
    planetData.map((project, index)=>{
        return <Planet 
        planetImage={project.planetImage} 
        position={project.planetPosition} 
        slug={`/projects/${project.projectSlug.current}`} 
        key={index}
        om={false}/>
    })}
    </group>
  )
}

export default Planets