import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import Error from "./Error.js";
import { motion } from "framer-motion";

import Title from "../components/project/Title.js";
import Image from "../components/global/Image.js";
import TextBlock from "../components/global/TextBlock.js";

const Project = () => {
  const [projectData, setProjectData] = useState(null);
  const [noProjectExists, setNoProjectExists] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[projectSlug.current == $slug]{
                  projectTitle,
                  projectSlug,
                  projectInfo,
                  projectImages
              }`,
        { slug }
      )
      .then((data) => {
        setProjectData(data[0]);
        if (data.length === 0) {
          setNoProjectExists(true);
        }
      })
      .catch();
    console.log(slug);
  }, [slug]);

  if (projectData) {
    return (
      <motion.div
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        exit={{ x: "100vw" }}
        transition={{
          type: "ease-in-out",
          duration: 0.4,
        }}
      >
        <Title projectData={projectData} />
        <section className="project__images-wrapper">
          {projectData.projectImages &&
            projectData.projectImages.map((projectImage, index) => {
              return (
                <Image
                  imageAsset={projectImage.entryImage}
                  styles={"project__image"}
                  alt={projectData.projectTitle}
                  key={index}
                />
              );
            })}
        </section>
        <section className="project__text-wrapper">
          <TextBlock text={projectData.projectInfo} />
          {/* {slug === "no-4-ice-t-s" ? (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="project__buy-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="323VR2N6YZZ8C"
              />
              <input type="hidden" name="currency_code" value="GBP" />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_GB/i/btn/btn_buynowCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Buy Now"
              />
            </form>
          ) : (
            ""
          )} */}
        </section>
      </motion.div>
    );
  } else if (noProjectExists === true) {
    return <Error />;
  } else {
    return null;
  }
};

export default Project;
