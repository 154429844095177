import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader, FrontSide, BackSide } from "three";

const Card = (props) => {

  const { cardImage } = props;

  const card = useRef();
  useFrame(({ clock }) => {
    card.current.rotation.y = clock.getElapsedTime() * 0.3;
  });

  const backValue = Math.floor(Math.random() * 4 + 1);
  const map = useLoader(TextureLoader, cardImage);
  const backMap = useLoader(
    TextureLoader,
    `/3d/textures/back_${backValue}.png`
  );
  const alpha = useLoader(TextureLoader, "/3d/textures/Alpha.png");

  return (
    <group ref={card}>
      <mesh>
        <planeGeometry args={[4.49, 7.44, 1, 1]} />
        <meshBasicMaterial
          side={FrontSide}
          map={map}
          alphaMap={alpha}
          transparent={true}
        />
      </mesh>
      <mesh ref={card} position={[0, 0, 0.1]}>
        <planeGeometry args={[4.49, 7.44, 1, 1]} />
        <meshBasicMaterial
          side={BackSide}
          map={backMap}
          alphaMap={alpha}
          transparent={true}
        />
      </mesh>
    </group>
  );
};

export default Card;
