import React, { useRef, Suspense } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import EnvMapHelper from "../../hooks/EnvMapHelper";
import { DoubleSide } from "three";

const degToRad = (value) => {
    return (value * Math.PI) / 180;
  };

const Model = (props) => {
  const { nodes } = useGLTF("/3d/models/HFLogo.gltf");
  const logo = useRef();
  const material = useRef();

  useFrame((state)=>{
    logo.current.position.y = Math.sin(state.clock.getElapsedTime()) * 0.5;
  })

  return (
    <Suspense>
    <group 
    {...props} 
    dispose={null} 
    ref={logo} 
    scale={[28, 28, 28]}
    rotation={[degToRad(90), degToRad(-20), 0]}
    >
      <EnvMapHelper material={material} envMapPath={'/3d/textures/pz.jpg'} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve003.geometry}>
        <meshStandardMaterial
        ref={material}
        color={'#ebf2ff'}
        metalness={0.9}
        roughness={0.3}
        side={DoubleSide}
        // envMapIntensity={8}
        />
        </mesh>
      
    </group>
    </Suspense>
  );
}
export default Model;

useGLTF.preload("/3d/models/HFLogo.gltf");

