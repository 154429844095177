import React from "react";

function Error() {
  
  return (
    <h1>error</h1>
  );
}

export default Error;
