import React from 'react'
import RandomWeight from '../../hooks/TextRandomiser'

const Info = () => {
  return (
    <section className='cards__info-wrapper'>
        <p><RandomWeight text={'Proudly presenting ॐ-Fries (that’s Om in audio, sounds a bit like E.T saying ‘home’ in the Spielberg classic) In collaboration with the instagram oracle @3rd.world.elite daily mindfulness cards.'} /></p>
        <p><RandomWeight text={'Remember before you can truly love someone else you’ve gotta love yourself first!!'} /></p>
        <p><RandomWeight text={'We usually donate all profits to an organisation or socially concious enterprise but this time we thought we’d go straight to the human source. Think of them as golden tickets for enlightenment! Everlasting gobstoppers of truth and reallness!'} /></p>
        <p><RandomWeight text={'Our mission here is to benefit the collective consciousness of now. It’s a fellowship not a corporation <3'} /></p>
        <p><RandomWeight text={'Hard copy packs of cards will be limited and freeeee gratis wolny libre senza manuahi soar. Available from Dover street Market London along with exclusive project based merchandise on February 4th 2023.'} /></p>
    </section>
  )
}

export default Info