import React from 'react';
import {Link} from 'react-router-dom';
import RandomWeight from '../../hooks/TextRandomiser';

import './Project.scss';

const Title = (props) => {

    const {projectData} = props;
  return (
    <div className='project__title'>
        <Link to={'/'} className='project__back'><RandomWeight text={'Back'} /></Link>
        <RandomWeight text={projectData.projectTitle} />
    </div>
  )
}

export default Title