import React, { useEffect, useState } from "react";

const useRandomFontWeight = (text) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    let newHtml = "";
    for (let i = 0; i < text.length; i++) {
      let weightValue = Math.floor(Math.random() * 2);
      newHtml += `<span style="font-weight: ${
        weightValue === 1 ? "bold" : "normal"
      }">${text[i]}</span>`;
    }
    setHtml(newHtml);
  }, [text]);
  return {
    __html: html,
  };
};

const RandomWeight = ({ text }) => {
  const randomWeight = useRandomFontWeight(text);
  return <span dangerouslySetInnerHTML={randomWeight} />;
};

export default RandomWeight;

