import React, { useState, useEffect }from "react";
import { motion } from "framer-motion";
import sanityClient from "../client";
import Title from "../components/cards/Title";
import Logo from "../components/cards/Logo";
import Scene from "../components/cards/Scene";
import Info from "../components/cards/Info";
import CardsGrid from "../components/cards/CardsGrid";
import CardPreview from "../components/cards/CardPreview";

const OmFries = () => {

  const [cardsData, setCardsData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(0);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "cards"] | order(_createdAt asc){
          cardTitle,
          cardSlug,
          "cardImage": cardImage.asset->url,
          cardDate
         }`
      )
      .then((data) => setCardsData(data))
      .catch(console.error);
  }, []);

  useEffect(()=>{
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);
    setCurrentWeek(weekNumber)
  },[])

  if(cardsData){
    return (
      <>      
      <Title />
      <motion.div
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        exit={{ x: "100vw" }}
        transition={{
          type: "ease-in-out",
          duration: 0.4,
        }}
      >
        
        <Logo />
        <Scene currentWeek={currentWeek} cardsData={cardsData}/>
        <Info />
        <CardsGrid 
        cardsData={cardsData} 
        currentWeek={currentWeek} 
        setPreviewOpen={setPreviewOpen} 
        setSelectedCard={setSelectedCard}
        setSelectedWeek={setSelectedWeek}
        />
        
      </motion.div>
      <CardPreview selectedWeek={selectedWeek} selectedCard={selectedCard} previewOpen={previewOpen} setPreviewOpen={setPreviewOpen}/>
      </>

    );    
  }

};

export default OmFries;
