import React, { useMemo, useRef, useEffect, Suspense } from "react";
import { extend, useFrame, useLoader } from "@react-three/fiber";
import {
  PointsMaterial,
  MathUtils,
  Float32BufferAttribute,
  TextureLoader,
} from "three";
import useCurrentTime from "../../hooks/useCurrentTime";

extend({ PointsMaterial });

const Stars = () => {
  const currentTime = useCurrentTime();
  const whiteStars = useLoader(TextureLoader, "/3d/textures/star-white.png");
  const blackStars = useLoader(TextureLoader, "/3d/textures/star.png");

  const pointsCloud = useRef();
  const material = useMemo(
    () =>
      new PointsMaterial({
        size: 1,
        transparent: true,
        map: currentTime?whiteStars:blackStars,
      }),
    [currentTime,blackStars,whiteStars]
  );

  useEffect(() => {
    const vertices = [];
    for (let i = 0; i < 5000; i++) {
      const x = MathUtils.randFloatSpread(1000);
      const y = MathUtils.randFloatSpread(1000);
      const z = MathUtils.randFloatSpread(1000);
      vertices.push(x, y, z);
    }

    const pointsPositions = new Float32BufferAttribute(vertices, 3);
    pointsCloud.current.geometry.setAttribute("position", pointsPositions);
  }, []);

  useFrame(()=>{
    pointsCloud.current.rotation.y += 0.005
  })
  return (
    <Suspense>
      <points ref={pointsCloud}>
        <bufferGeometry attach="geometry" />
        <pointsMaterial attach="material" args={[material]} />
      </points>
    </Suspense>
  );
};

export default Stars;
