import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Info from "./components/global/Info";
import Logo from "./components/global/Logo";
import Home from "./views/Home";
import Project from "./views/Project";
import OmFries from "./views/OmFries";
// import Cards from './views/Cards';
// import Error from './views/Error';

import { AnimatePresence } from "framer-motion";
import useCurrentTime from "./hooks/useCurrentTime";

import "./styles/global-styles.scss";
function App() {
  const location = useLocation();
  const currentTime = useCurrentTime();

  useEffect(() => {
    if (currentTime) {
      document.documentElement.style.setProperty(
        "--background-color",
        "#010033"
      );
      document.documentElement.style.setProperty("--text-color", "#FFFFFF");
    } else {
      document.documentElement.style.setProperty(
        "--background-color",
        "#FFFFFF"
      );
      document.documentElement.style.setProperty("--text-color", "#000000");
    }
  }, [currentTime]);

  useEffect(()=>{
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
     }
     window.addEventListener('resize', appHeight)
     appHeight();
     return () => window.removeEventListener("resize", appHeight);
  },[])

  return (
    <>
      {/* <Info /> */}
      {/* <Logo /> */}
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<Home key={"home"} />} path="/" />
          <Route element={<Project key={"project"} />} path="/projects/:slug" />
          <Route element={<OmFries key={"cards"} />} path="/OmFries" />
          {/* <Route element={<Error key={"error"} />} path="*" /> */}
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
