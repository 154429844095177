import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei';
import Model from './Model';
import './Logo.scss';

const Logo = () => {
  return (
    <>

    <section className='logo__wrapper'>
        <div className='logo__canvas-wrapper'>
        <Canvas flat>
            <pointLight position={[0, 50, 50]} intensity={1}/>
            {/* <pointLight position={[0, 10, -100]} intensity={1}/> */}
            <ambientLight intensity={0.1} />
            <OrbitControls />
            <Model />
        </Canvas>
        </div>
        <span className='logo__link'>Part of the <a href="http://globalbeaming.com/" target="_blank" rel='noreferrer'>
            Global Beaming
        </a>&trade; Network</span>
    </section>
    </>
  )
}

export default Logo