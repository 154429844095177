import { useState, useEffect } from "react";

const useCurrentTime = () => {
  //false = day, true = night
  const [currentTime, setCurrentTime] = useState(false);

  useEffect(() => {
    let time = new Date().getHours();
    if (time <= 8 || time >= 20) {
      setCurrentTime(true);
    } else {
      setCurrentTime(false);
    }
  }, []);

  return currentTime;
};

export default useCurrentTime;
