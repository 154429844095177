import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client.js";

import Scene from "../components/home/Scene.js";

function Home() {

  const [planetData, setPlanetData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projects"]{
          projectTitle,
          projectSlug,
          "planetImage": planetImage.asset->url,
          planetPosition
         }`
      )
      .then((data) => setPlanetData(data))
      .catch(console.error);
  }, []);


  return (
    <>
      <motion.div  
      initial={{x: '-100vw'}}
      animate={{x: 0}}
      exit={{x: '-100vw'}}
      transition={ {
        type: "ease-in-out", 
        duration: 0.4
      }}>
        {planetData?(
          <Scene planetData={planetData} />
        ):('')}
      </motion.div>
    </>
  );
}

export default Home;
