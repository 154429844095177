import React from 'react'
import { CardImage } from './CardImage';
import CardPlaceholder from './CardPlaceholder';
import './Cards.scss';

const CardsGrid = (props) => {
    const {cardsData, currentWeek, setPreviewOpen, setSelectedCard, setSelectedWeek} = props;

  return (
    <section className='cards__grid-wrapper'>
        {cardsData &&
        cardsData.map((card, index)=>{
            if(index < currentWeek){
                return <CardImage 
                card={card} 
                key={index} 
                week={index + 1} 
                setPreviewOpen={setPreviewOpen} 
                setSelectedCard={setSelectedCard}
                setSelectedWeek={setSelectedWeek}/>
            } else {
                return <CardPlaceholder card={card} key={index} week={index + 1}/>
            }
        })}
    </section>
  )
}

export default CardsGrid