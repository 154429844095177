import React from "react";
import { PortableText } from "@portabletext/react";

const TextBlock = (props) => {
  const { text } = props;

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  return <PortableText value={text} components={PortableTextComponents} />;
};

export default TextBlock;
