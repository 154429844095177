import React from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";

const Planet = (props) => {

  const { planetImage, position, slug, om } = props;
  const planet = useRef();
  const intPosition = position.split(',').map(Number);
  const map = useLoader(TextureLoader, planetImage);
  const navigate = useNavigate();
  const handleClick = () => navigate(slug);

  useFrame(({clock})=>{
    if(om){
      planet.current.rotation.y = -clock.getElapsedTime() * 0.2;
      planet.current.position.y = Math.sin(clock.getElapsedTime()) * 1;
    } else {
      planet.current.rotation.y = clock.getElapsedTime() * 0.1;
    }
  })

  return (
    <mesh 
    ref={planet}
    position={intPosition} 
    onPointerEnter={()=>{
        document.documentElement.style.cursor = 'pointer'
    }}
    onPointerLeave={()=>{
        document.documentElement.style.cursor = 'default'
    }}
    onClick={handleClick}>
      <sphereGeometry args={[0.8, 32, 64]}/>
      <meshBasicMaterial 
      color={'#FFFFFF'}
      map={map}
      />
    </mesh>
  );
};

export default Planet;
