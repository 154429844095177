import React from "react";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, OrbitControls } from "@react-three/drei";
import useWindowSize from "../../hooks/useWindowSize";

import RandomWeight from "../../hooks/TextRandomiser";
import Stars from "../home/Stars";

import Card from "./Card";

const Scene = (props) => {
  const { cardsData, currentWeek } = props;
  const windowSize = useWindowSize();

  return (
    <section className="cards__scene-wrapper">
      <Canvas>
        {windowSize.width >= 750 ? (
          <>
            <PerspectiveCamera makeDefault position={[0, 0, 10]} />
            <OrbitControls enableZoom={false} />
          </>
        ) : (
          <PerspectiveCamera makeDefault position={[0, 0, 15]} />
        )}
        <Stars />
        <Card cardImage={cardsData[currentWeek - 1].cardImage} />
      </Canvas>
      <div className="cards__main-caption">
        <RandomWeight
          text={`Latest card: Week ${currentWeek} – ${
            cardsData[currentWeek - 1].cardTitle
          }`}
        />
      </div>
    </section>
  );
};

export default Scene;
