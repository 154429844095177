import React from 'react'

import './Cards.scss';

const CardPlaceholder = (props) => {
    const {card, week} = props;
    const cardBack = Math.floor(Math.random() * 4 + 1);
    const releaseDate = new Date(card.cardDate);
    const day = releaseDate.getDate();
    const month = releaseDate.getMonth();
    const year = releaseDate.getFullYear();
    
  return (
    <div className='cards__grid-item placeholder'>
        <img src={`/3d/textures/back_${cardBack}.png`} alt={'COMING SOON'}  className="cards__grid-image"/>
        <p>Week {week}: Coming {('0'+(day)).slice(-2)}/{('0'+(month + 1)).slice(-2)}/{year}</p>
    </div>
  )
}

export default CardPlaceholder;