import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import useWindowSize from "../../hooks/useWindowSize";

import Stars from "./Stars";
import Planets from "./Planets";
import Planet from "./Planet";

import "./Home.scss";

const Scene = (props) => {
  const { planetData } = props;
  const windowSize = useWindowSize();

  return (
    <div className="home__canvas-wrapper">
      <Canvas>
        <ambientLight intensity={1} />
        {windowSize.width >= 750 ? (
          <PerspectiveCamera makeDefault position={[0, 0, 10]} />
        ) : (
          <PerspectiveCamera makeDefault position={[0, 0, 20]} />
        )}

        <OrbitControls />
        <Stars />
        <Planets planetData={planetData} />
        <Planet
          planetImage={"/OmFries.png"}
          position={"0,0,0"}
          slug={"/OmFries"}
          om={true}
        />
      </Canvas>
    </div>
  );
};

export default Scene;
