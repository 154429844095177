import React from 'react';
import {Link} from 'react-router-dom';
import RandomWeight from '../../hooks/TextRandomiser';

import './Cards.scss';

const Title = (props) => {

  return (
    <div className='cards__title'>
        <Link to={'/'} className='cards__back'><RandomWeight text={'Back'} /></Link>
        <RandomWeight text={'No.3 Om-Fries X 3rd World Elite Daily mindfulness cards'} />
    </div>
  )
}

export default Title