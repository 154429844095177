import React from 'react'
import useProgressiveImg from "../../hooks/useProgressiveImage";
import './Cards.scss';

export const CardImage = (props) => {
    const {card, week, setPreviewOpen, setSelectedCard, setSelectedWeek} = props;

    const openPreview = () =>{
        setPreviewOpen(true);
        setSelectedCard(card);
        setSelectedWeek(week);
    }

    const smallUrl = `${card.cardImage}?q=10`;
    const largeUrl = `${card.cardImage}`;
    const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <button className='cards__grid-item' 
    onClick={openPreview}
    >
        <img 
        src={src} 
        alt={card.cardTitle}  
        className="cards__grid-image"
        style={{
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}
          />
        <p>Week {week}: {card.cardTitle}</p>
    </button>
  )
}
